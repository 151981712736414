@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css);
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&family=Inter:wght@100..900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css");
@import url("./cookie.css");

/*
  1. Use a more-intuitive box-sizing model.
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  3. Allow percentage-based heights in the application
*/
html,
body {
  height: 100%;
  scroll-behavior: smooth;
}
:root {
  --source: "Source Sans 3";
}
/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  padding-top: 1px;
  background: #0d0d0d;
}
.background-image {
  position: relative;
}

.source {
  font-family: "Source Sans 3";
}

.background-img {
  position: relative;
}

.bg-image {
  background-image: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1685112932/Looper_BG_seuzhc.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (max-width: 999px) {
  .background-image::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1685112932/Looper_BG_seuzhc.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
  }
}
@media (min-width: 1000px) {
  .background-img::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1687267335/Group_14158_rikogl.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center bottom;
    transform: scaleX(-1);
    z-index: -1;
  }
  .bg-header {
    background: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1686853999/Line_5_1_e9mwkz.png");
    background-repeat: no-repeat;
    background-size: 100%;
    /* // background-position: 20%; */
  }
  .bg-corporate {
    background: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1714027130/corporate-liness_taczam.png");
    background-repeat: no-repeat;
    background-size: 100%;
    /* // background-position: 20%; */
  }
  .bg-academy {
    background: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1688117827/Line_8_neipqd.png");
    background-repeat: no-repeat;
    background-size: 100%;
    /* // background-position: 20%; */
  }
  .bg-img {
    background-image: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1685112932/Looper_BG_seuzhc.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
}
/*
  6. Improve media defaults
*/
.container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-right: 20px;
  padding-left: 20px;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

.btn-base {
  border-radius: 10px;
  background-color: #0073ff;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  outline: 0px;
  border: 0px;
}
.btn-base:hover {
  box-shadow: inset 0 -1px 1px 100px rgb(0 0 0 / 15%),
    0 8px 5px -5px rgb(0 0 0 / 20%);
}

.btn-white {
  border-radius: 10px;
  color: #1f3569;
  background-color: #fff;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  outline: 0px;
  border: 0px;
}
.btn-white:hover {
  box-shadow: inset 0 -1px 1px 100px rgb(0 0 0 / 15%),
    0 8px 5px -5px rgb(0 0 0 / 20%);
}
.btn-outline-white {
  border-radius: 10px;
  color: #fff;
  background-color: transparent;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  outline: 0px;
  border: 2px solid #fff;
}
.btn-outline-white:hover {
  box-shadow: inset 0 -1px 1px 100px rgb(0 0 0 / 15%),
    0 8px 5px -5px rgb(0 0 0 / 20%);
}
.broad {
  background: linear-gradient(
      to bottom,
      rgba(31, 53, 105, 0.8),
      rgba(31, 53, 105, 0.8)
    ),
    url("./images/home.jpg");
  padding-bottom: 7rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.top-left-bottom-right {
  background-image: url(./images/topleft.png), url(./images/bottomright.png);
  background-repeat: no-repeat;
  background-position: top left, bottom right;
}

.card-base .content {
  overflow: hidden;
  padding: 2rem;
}
.text-body {
  padding: 1rem;
}
.products h3 {
  font-size: 2rem;
}

@media (max-width: 600px) {
  .break {
    font-size: 1rem !important;
  }
  .card-base .content {
    padding: 0px;
  }
  .gif {
    max-height: 250px !important;
  }
}
.images {
  position: relative;
  z-index: 1;
}

.form-input {
  display: flex;
  padding: 14px;
  align-items: center;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  /* background: rgba(30, 30, 30, 0.1); */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #000;
}
.markdown ol {
  list-style: decimal !important;
  padding-left: 20px;
}
.markdown ul {
  list-style: disc !important;
  padding-left: 40px;
}
.box-ioa {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.04);
}
.product_image {
  background: linear-gradient(180deg, #0d1417 0%, #0d0d0d 100%);
  border: 1px solid linear-gradient(180deg, #1a2f39 0%, #0d0d0d 100%);
  border-radius: 16px;
}
.industry_card {
  background: linear-gradient(180deg, #101011 0%, #0d0d0d 100%);
}
.abstract-lines {
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0.1)
    ),
    url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1725556611/abstract-lines.fe979ff481879704b5c8_oxmk8b.png");
  background-repeat: no-repeat;
  background-position: bottom;
  /* background-size: cover; */
}
.bg-africa {
  background: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1725556221/backgroundGradient_qbvruy.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
}
.bg-top {
  background: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1725556221/backgroundGradient_qbvruy.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
}

/* Position the modal relative to the chat container */
.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 0px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 474px;
}

/* Chat window styling */
.chat-window {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  height: 82vh;
  overflow-y: auto;
  position: relative;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  background: #0d0d0d;
  box-shadow: 0px 2px 8px 1px rgba(188, 188, 188, 0.15);
}

.ai-logo {
  margin: 0 auto 0;
}

.ai-customer-rep {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 180%; /* 32.4px */
  margin-top: 20px;
}

/* Chat header styling */
.chat-header {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #030303;
  height: 68px;
  border-radius: inherit;
}

.pba-header {
  background: linear-gradient(90deg, #c2d6ef 12.8%, #01adff 56.8%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 169.231% */
  text-align: center;
}

/* Chat intro styling */
.chat-intro {
  padding: 10px;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chat-intro-message {
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 25.2px */
  max-width: 396px;
  margin: 0 auto;
}

.action-button {
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #0d0d0d;
  display: inline-flex;
  padding: 51px 16px 67px 16px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: 14px;
}

.prefilled-questions {
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 394px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 8px;
  background: #fff;
  margin: 24px auto 0;
}

.prefilled-que {
  width: 100%;
  display: flex;
  padding: 15px 4px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #030303;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 21.6px */
}
/* Chat content and message styling */
.chat-content {
  display: flex;
  flex-direction: column;
  max-height: 70%;
  width: 100%;
  position: relative;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70vh;
  padding-bottom: 100px;
}

.message {
  padding: 8px 12px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.message.user {
  align-self: flex-end;
  width: 100%;
  max-width: 80%;
  border-radius: 4px;
  background: #fff;
  display: inline-flex;
  padding: 12px 46px 12px 10px;
  color: #0d0d0d;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%;
  letter-spacing: 0.12px;
}

.message.bot {
  position: relative;
  align-self: flex-start;
  width: 100%;
  max-width: 80%;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 21.6px */
  letter-spacing: 0.12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.arrow-dwon {
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(4, 60, 127, 0.4);
}

.altMarkdown {
  background: #035982;
  padding: 12px 6px 12px 10px;
  color: rgba(255, 255, 255, 0.8);
}

.altMarkdown h1 {
  font-size: 24px;
  font-weight: 600;
}
.altMarkdown h2 {
  font-size: 20px;
  font-weight: 500;
}
.altMarkdown h3 {
  font-size: 18px;
  font-weight: 500;
}
.altMarkdown ul {
  margin-left: 30px;
  list-style: disc;
  margin-top: 10px;
  margin-bottom: 10px;
}

.altMarkdown ol {
  margin-left: 30px;
  list-style: decimal;
  margin-top: 10px;
  margin-bottom: 10px;
}

.feedback-msg {
  /* position: absolute;
  bottom: -60px; */
  max-width: 80%;
  border-radius: 4px;
  background: #035982;
  padding: 12px 6px 12px 10px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

.like-dislike {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}

.message.bot table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.message.bot table th,
.message.bot table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.message.bot table th {
  background-color: #0073ff;
  color: white;
}

.message.bot table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.message.bot table tr:hover {
  background-color: #f1f1f1;
}

.message.bot table th {
  padding-top: 12px;
  padding-bottom: 12px;
}

/* Input area styling */
.input-area {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: auto !important;
  background: #030303;
  padding: 10px 20px;
  border-radius: inherit;
}

.input-area textarea {
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  background: #0d0d0d;
  padding: 8px;
  resize: none;
  color: #fff;
  padding: 12px 10px 11px 12px;
  width: 100%;
  outline: 0;
}

.input-area button {
  position: absolute;
  z-index: 3;
  /* top: 10; */
  right: 30px;
  border: none;
  background: none;
  margin-left: 10px;
  cursor: pointer;
}

.input-area button img {
  width: 24px;
  height: 24px;
}

/* Loading animation styling */
.loading {
  display: flex;
  align-items: center;
  background: inherit;
  padding: 0 12px;
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: #0073ff;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1.4s infinite ease-in-out both;
}

/* Bounce animation for loading dots */
@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.dot:nth-child(1) {
  animation-delay: -0.32s;
}

.dot:nth-child(2) {
  animation-delay: -0.16s;
}

/* Modal styling */
.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  border-radius: 10px;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.modal h2 {
  margin-bottom: 10px;
}

.modal p {
  margin-bottom: 20px;
}

.modal-actions {
  display: flex;
  justify-content: space-around;
}

.confirm-btn,
.cancel-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-btn {
  background-color: #0073ff;
  color: white;
}

.cancel-btn {
  background-color: #f1f1f1;
  color: black;
}

.MuiInputBase-root {
  padding: 0;
  margin-top: 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #0d0d0d;
  height: 40px;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 9px !important;
  font-size: 12px !important;
}

/* Adjust chat container position for larger screens */
@media only screen and (min-width: 768px) {
  .chatbot-container {
    right: 20px;
  }
  .action-button {
    padding: 51px 19px 67px 20px;
    font-size: 16px;
  }
}

::-webkit-scrollbar {
  display: inline-flex;
  width: 0.45vw;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, #0d0d0d);
  border-radius: 10px;
}
